import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardTemplate from 'components/layout';
import Can from 'components/can';
import pages from 'pages';
import Login, { Callback } from 'pages/login';
import Logout from 'pages/logout';
import ComingSoon from 'pages/ComingSoon';
import ReplenishmentSuppliers from 'pages/replenishment/suppliers-calendar';
import ExportSalesForecast from 'pages/replenishment/export-sales-forecast';
import ProtectedRoute from './ProtectedRoute';
import OutOfStockExport from '../pages/export/inventory/out-of-stock';
import InStockExport from '../pages/export/inventory/in-stock';
import env from 'config/env';

const isProduction = env('STAGE_LEVEL') === 'production';

export default function Routes() {
  return (
    <div id="app">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/callback" component={Callback} />
        <Route exact path="/logout" component={Logout} />

        <ProtectedRoute path="*">
          <DashboardTemplate>
            <Switch>
              <Route path="/" component={pages.Dashboard} exact />
              <Route path="/buyers" component={pages.BuyersList} exact />
              <Route
                path="/buyers/:id/categories"
                component={pages.BuyerCategoriesList}
                exact
              />
              <Route path="/categories" component={pages.Categories} exact />
              <Route
                path="/categories/create"
                component={pages.CreateCategory}
                exact
              />
              <Route
                path="/categories/:id/edit"
                component={pages.EditCategory}
                exact
              />
              <Route path="/labels" component={pages.LabelsList} exact />
              <Route
                path="/labels/create"
                component={pages.CreateLabel}
                exact
              />
              <Route
                path="/labels/:id/edit"
                component={pages.EditLabel}
                exact
              />
              <Route path="/serial-items" component={pages.SerialItems} exact />
              <Route
                path="/products/create"
                component={() => (
                  <Can
                    component={pages.CreateProduct}
                    action="create"
                    subject="products"
                  />
                )}
                exact
              />
              <Route
                path="/products/:id/edit"
                component={() => (
                  <Can
                    component={pages.EditProduct}
                    action="read"
                    subject="products"
                  />
                )}
                exact
              />
              <Route
                path="/products/:id/orders"
                component={pages.ProductOrders}
                exact
              />
              <Route
                path="/products"
                component={() => (
                  <Can
                    component={pages.ProductsList}
                    action="read"
                    subject="products"
                  />
                )}
                exact
              />
              <Route
                path="/products/:id/stores"
                component={pages.ProductStoresList}
                exact
              />
              <Route
                path="/inventory/:tab?"
                component={pages.Inventory}
                exact
              />
              <Route path="/suppliers" component={pages.SuppliersList} exact />
              <Route
                path="/suppliers/create"
                component={pages.CreateSupplier}
                exact
              />
              <Route
                path="/suppliers/:id/:tab"
                component={pages.EditSupplier}
                exact
              />
              <Route path="/stores" component={pages.Stores} exact />
              <Route
                path="/stores/create"
                component={pages.CreateStore}
                exact
              />
              <Route
                path="/stores/:id/:tab"
                component={pages.EditStore}
                exact
              />
              <Route
                path="/manage/warehouses"
                component={pages.NewWarehouses}
                exact
              />
              <Route path="/warehouses" component={pages.Warehouses} exact />
              <Route
                path="/warehouses/create"
                component={pages.CreateWarehouse}
                exact
              />
              <Route
                path="/warehouses/:id/:tab"
                component={pages.EditWarehouse}
                exact
              />
              <Route path="/customers" component={pages.Customers} exact />
              <Route
                path="/customers/create"
                component={pages.CreateCustomer}
                exact
              />
              <Route
                path="/customers/:id/edit"
                component={pages.EditCustomer}
                exact
              />
              <Route path="/users" component={pages.Users} exact />
              <Route path="/users/create" component={pages.CreateUser} exact />
              <Route path="/users/:id/edit" component={pages.EditUser} exact />
              <Route path="/roles" component={pages.RolesList} exact />
              <Route path="/roles/create" component={pages.CreateRole} exact />
              <Route path="/roles/:id" component={pages.EditRole} exact />
              <Route
                path="/requested-purchase-orders/:id/"
                component={pages.ViewRequestedPurchaseOrder}
                exact
              />
              <Route
                path="/requested-purchase-orders/:id/:tab"
                component={pages.ViewRequestedPurchaseOrder}
                exact
              />
              <Route
                path="/merge-purchase-orders/"
                component={pages.MergePurchaseOrders}
                exact
              />
              <Route
                path="/purchase-orders"
                component={() => (
                  <Can
                    component={pages.PurchaseOrders}
                    action="read"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/bills"
                component={pages.SupplierBills}
                exact
              />
              <Route
                path="/purchase-orders/create"
                component={() => (
                  <Can
                    component={pages.PurchaseOrderTypes}
                    action="create"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/create/manual"
                component={() => (
                  <Can
                    component={pages.CreatePurchaseOrder}
                    action="create"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/upload/:type"
                component={() => (
                  <Can
                    component={pages.UploadPurchaseOrder}
                    action="create"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-order/export"
                component={pages.ExportPurchaseOrders}
                exact
              />
              <Route
                path="/cancel-purchase-order-file"
                component={pages.CancelPurchaseOrderFile}
                exact
              />
              <Route
                path="/demand/forecast-schedules/create"
                component={() => (
                  <Can
                    component={pages.CreateDemandForecastSchedule}
                    action="create"
                    subject="demand_forecast_schedule"
                  />
                )}
                exact
              />
              <Route
                path="/demand/forecast-schedules/:tab?"
                component={() => (
                  <Can
                    component={pages.DemandForecastSchedules}
                    action="read"
                    subject="demand_forecast_schedule"
                  />
                )}
                exact
              />
              <Route
                path="/demand/forecast-schedules/edit/:id"
                component={() => (
                  <Can
                    component={pages.EditDemandForecastSchedule}
                    action="update"
                    subject="demand_forecast_schedule"
                  />
                )}
                exact
              />
              <Route
                path="/demand/create-demand-forecast"
                component={pages.CreateDemandForecast}
                exact
              />
              <Route
                path="/demand/create-demand-forecast/scheduled"
                component={pages.CreateScheduledForecast}
                exact
              />
              <Route
                path="/demand/create-demand-forecast/unscheduled"
                component={pages.CreateUnscheduledForecast}
                exact
              />
              <Route
                path="/purchase-orders/:id/"
                component={() => (
                  <Can
                    component={pages.ViewPurchaseOrder}
                    action="read"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/:id/edit"
                component={() => (
                  <Can
                    component={pages.EditPurchaseOrder}
                    action="update"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/:id/receive"
                component={() => (
                  <Can
                    component={pages.ReceivePurchaseOrder}
                    action="receive"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/purchase-orders/:id/unreceive"
                component={pages.UnreceivePurchaseOrder}
                exact
              />
              <Route
                path="/purchase-orders/:id/backorder"
                component={pages.BackOrderPurchaseOrder}
                exact
              />
              <Route
                path="/purchase-orders/:id/:tab"
                component={() => (
                  <Can
                    component={pages.ViewPurchaseOrder}
                    action="read"
                    subject="purchase_orders"
                  />
                )}
                exact
              />
              <Route
                path="/back-orders"
                component={pages.UnfulfillableOrders}
                exact
              />
              <Route
                path="/request-stock-transfers"
                component={() => (
                  <Can
                    component={pages.CreateStockTransfer}
                    action="create"
                    subject="stock_transfers"
                  />
                )}
                exact
              />
              <Route
                path="/stock-transfers"
                component={() => (
                  <Can
                    component={pages.StockTransfers}
                    action="read"
                    subject="stock_transfers"
                  />
                )}
                exact
              />
              <Route
                path="/stock-transfers/:id"
                component={() => (
                  <Can
                    component={pages.ViewStockTransfer}
                    action="read"
                    subject="stock_transfers"
                  />
                )}
                exact
              />
              <Route
                path="/stock-transfers/:id/edit"
                component={() => (
                  <Can
                    component={pages.EditStockTransfer}
                    action="update"
                    subject="stock_transfers"
                  />
                )}
                exact
              />
              <Route path="/sales" component={ComingSoon} exact />
              <Route path="/marketing" component={ComingSoon} exact />
              <Route path="/reports" component={pages.Reports} />
              <Route path="/exports" component={pages.Exports} exact />
              <Route
                path="/exports/suppliers"
                component={() => (
                  <Can
                    component={pages.ExportSuppliers}
                    action="export_suppliers"
                    subject="exporters"
                  />
                )}
                exact
              />
              <Route
                path="/exports/inventory"
                component={() => (
                  <Can
                    component={pages.InventoryExporter}
                    action="export_stock"
                    subject="exporters"
                  />
                )}
                exact
              />
              <Route
                path="/exports/inventory/out-of-stock"
                component={() => (
                  <Can
                    component={OutOfStockExport}
                    action="export_stock"
                    subject="exporters"
                  />
                )}
                exact
              />
              <Route
                path="/exports/inventory/in-stock"
                component={() => (
                  <Can
                    component={InStockExport}
                    action="export_stock"
                    subject="exporters"
                  />
                )}
                exact
              />
              <Route
                path="/exports/products"
                component={() => (
                  <Can
                    component={pages.ExportProducts}
                    action="export_products"
                    subject="exporters"
                  />
                )}
                exact
              />
              <Route path="/import" component={pages.Imports} exact />
              <Route
                path="/import/products/legacy"
                component={() => (
                  <Can
                    component={pages.ImportProducts}
                    action="import_products"
                    subject="importers"
                  />
                )}
                exact
              />
              <Route
                path="/import/products"
                component={() => (
                  <Can
                    component={pages.NewImportProducts}
                    action="import_products"
                    subject="importers"
                  />
                )}
                exact
              />
              <Route
                path="/import/products-ranging"
                component={pages.ImportProductsRanging}
                exact
              />
              <Route
                path="/import/product-stocks"
                component={() => (
                  <Can
                    component={pages.ImportProductStocks}
                    action="import_stock"
                    subject="importers"
                  />
                )}
                exact
              />
              <Route
                path="/import/suppliers"
                component={() => (
                  <Can
                    component={pages.ImportSuppliers}
                    action="import_supplier"
                    subject="importers"
                  />
                )}
                exact
              />
              <Route
                component={() => (
                  <Can
                    component={pages.Settings}
                    action="read"
                    subject="settings"
                  />
                )}
                path="/settings"
              />
              <Route component={pages.Tags} path="/tags" />
              <Route component={pages.Statuses} path="/statuses/:type?" />
              <Route
                component={ReplenishmentSuppliers}
                path="/restock-schedule"
                exact
              />
              <Route
                component={ExportSalesForecast}
                path="/replenishment/generate-draft-po"
                exact
              />
              <Route component={pages.Brands} path="/brands" exact />
              <Route component={pages.Integrations} path="/integrations" />
              <Route component={pages.Shipping} path="/shipping/:tab?" exact />
              <Route
                component={pages.CreateShippingMethod}
                path="/shipping/methods/create"
                exact
              />
              <Route
                component={pages.EditShippingMethod}
                path="/shipping/methods/view/:id"
                exact
              />
              <Route
                component={pages.CreateShippingCarrier}
                path="/shipping/carriers/create"
                exact
              />
              <Route
                component={pages.EditShippingCarrier}
                path="/shipping/carriers/edit/:id"
                exact
              />
              <Route
                component={() => (
                  <Can
                    component={pages.Coupons}
                    action="read"
                    subject="coupons"
                  />
                )}
                path="/coupons"
                exact
              />
              <Route
                component={() => (
                  <Can
                    component={pages.CreateCoupons}
                    action="create"
                    subject="coupons"
                  />
                )}
                path="/coupons/create"
                exact
              />
              <Route
                component={() => (
                  <Can
                    component={pages.EditCoupon}
                    action="update"
                    subject="coupons"
                  />
                )}
                path="/coupons/:id/edit"
                exact
              />
              <Route
                component={pages.PrintProductLabels}
                path="/print-labels"
                exact
              />
              <Route
                component={pages.StockMovementsTracker}
                path="/stock-movements/tracker"
                exact
              />
              <Route
                component={pages.DemandAlgorithmBuilder}
                path="/demand/forecast-algorithms/"
                exact
              />
              <Route
                component={pages.CreateDemandAlgorithmBuilder}
                path="/demand/forecast-algorithms/create"
                exact
              />
              <Route
                component={pages.EditDemandAlgorithm}
                path="/demand/forecast-algorithms/:id"
                exact
              />
              <Route
                component={pages.Attributes}
                path="/attribute/:tab?"
                exact
              />
              <Route
                component={pages.CreateAttributeSet}
                path="/attribute/sets/create"
                exact
              />
              <Route
                component={pages.EditAttributeSet}
                path="/attribute/sets/:id"
                exact
              />
              <Route
                component={pages.CreateAttributeGroup}
                path="/attribute/groups/create"
                exact
              />
              <Route
                component={pages.EditAttributeGroup}
                path="/attribute/groups/:id"
                exact
              />
              <Route
                component={pages.CreateAttribute}
                path="/attribute/attributes/create"
                exact
              />
              <Route
                component={pages.EditAttribute}
                path="/attribute/attributes/:id"
                exact
              />
              <Route
                component={pages.FreeFormCosts}
                path="/free-form-costs/:tab?"
                exact
              />
              <Route
                component={pages.JobTemplates}
                path="/templates/jobs"
                exact
              />
              <Route
                component={pages.CreateJobTemplate}
                path="/templates/jobs/create"
                exact
              />
              <Route
                component={pages.EditJobTemplate}
                path="/templates/jobs/:id"
                exact
              />
              {!isProduction && (
                <Switch>
                  <Route
                    component={() => (
                      <Can
                        component={pages.DiscountRules}
                        action="read"
                        subject="bulk_discounts"
                      />
                    )}
                    path="/discount-rules/"
                    exact
                  />
                  <Route
                    component={() => (
                      <Can
                        component={pages.CreateDiscountRule}
                        action="create"
                        subject="bulk_discounts"
                      />
                    )}
                    path="/discount-rules/create"
                    exact
                  />
                  <Route
                    component={() => (
                      <Can
                        component={pages.EditDiscountRule}
                        action="update"
                        subject="bulk_discounts"
                      />
                    )}
                    path="/discount-rules/:id"
                    exact
                  />
                </Switch>
              )}
            </Switch>
          </DashboardTemplate>
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
