import { useCallback, useEffect, useState } from 'react';
import clone from 'lodash/clone';
import get from 'lodash/get';
import set from 'lodash/set';
import createValidator from 'components/form/validation';

export default function useMiniForm({
  initialValues,
  onSubmit,
  validation
} = {}) {
  let [status, setStatus] = useState({
    submitting: false,
    succeeded: false
  });

  let [values, setValues] = useState(initialValues || {});

  useEffect(() => {
    if (initialValues) {
      setValues(normalizeValues(initialValues));
    }
  }, [initialValues]);

  let change = useCallback((name, value) => {
    setValues(prev => {
      let next = clone(prev);
      set(next, name, value);
      return next;
    });
  }, []);

  const update = useCallback((name, value) => {
    setValues(prev => {
      let payload = {};
      if (typeof name === 'string') {
        payload[name] = value;
      } else if (typeof name === 'function') {
        payload = { ...name(prev) };
      } else {
        payload = { ...name };
      }
      return { ...prev, ...payload };
    });
  }, []);

  let getFieldValue = name => get(values, name);

  let initialize = useCallback(nextValues => setValues(nextValues), []);

  let reset = useCallback(() => setValues(normalizeValues(initialValues)), [
    initialValues
  ]);

  let validate = useCallback(
    rules => {
      if (rules) {
        let syncError = createValidator(rules)(values);
        if (syncError) {
          setStatus({
            error: syncError,
            submitting: false,
            succeeded: false
          });
          return false;
        }
      }
      setStatus({
        submitting: false,
        succeeded: false
      });
      return true;
    },
    [values]
  );

  let clearErrors = useCallback(() => {
    setStatus(prev => ({
      ...prev,
      error: undefined
    }));
  }, []);

  let submit = useCallback(
    async (event, ...params) => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (validate(validation) === false) {
        return;
      }
      setStatus({
        submitting: true,
        succeeded: false
      });
      try {
        await onSubmit(values, event, ...params);
        setStatus({
          submitting: false,
          succeeded: true
        });
      } catch (error) {
        setStatus({
          error,
          submitting: false,
          succeeded: false
        });
      }
    },
    [onSubmit, validate, validation, values]
  );

  let getInputPropsFor = name => ({
    onChange: value => {
      change(name, value);
    },
    value: get(values, name)
  });

  let getCheckboxPropsFor = name => ({
    onChange: value => {
      change(name, value);
    },
    checked: !!get(values, name)
  });

  return {
    update,
    change,
    clearErrors,
    get: getFieldValue,
    error: status.error,
    initialize,
    getInputPropsFor,
    getCheckboxPropsFor,
    reset,
    submitting: status.submitting,
    submit,
    succeeded: status.succeeded,
    validate,
    values
  };
}

function normalizeValues(values) {
  return typeof values === 'function' ? values() : values || {};
}
