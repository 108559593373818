import { lazy } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Dashboard: lazy(() => import('./dashboard')),
  ProductsList: lazy(() => import('./products')),
  CreateProduct: lazy(() => import('./create-product')),
  EditProduct: lazy(() => import('./edit-product')),
  ProductOrders: lazy(() => import('./product-orders')),
  //DraftPurchaseOrdersList: lazy(() => import('./draftPurchaseOrder/list')),
  UploadPurchaseOrder: lazy(() => import('./upload-purchase-order')),
  ExportPurchaseOrders: lazy(() =>
    import('./purchaseOrder/exportPurchaseOrder')
  ),
  BuyersList: lazy(() => import('./user/buyer/list')),
  BuyerCategoriesList: lazy(() => import('./user/buyerCategory/list')),
  Categories: lazy(() => import('./categories')),
  CreateCategory: lazy(() => import('./create-category')),
  EditCategory: lazy(() => import('./edit-category')),
  SuppliersList: lazy(() => import('./suppliers')),
  CreateSupplier: lazy(() => import('./create-supplier')),
  EditSupplier: lazy(() => import('./edit-supplier')),
  ProductStoresList: lazy(() => import('./product-stores')),
  Stores: lazy(() => import('./stores')),
  CreateStore: lazy(() => import('./create-store')),
  EditStore: lazy(() => import('./edit-store')),
  Inventory: lazy(() => import('./inventory')),
  Reports: lazy(() => import('./report')),
  Exports: lazy(() => import('./export/root')),
  InventoryExporter: lazy(() => import('./export/inventory')),
  ExportProducts: lazy(() => import('./export/products')),
  Imports: lazy(() => import('./import/root')),
  ImportProducts: lazy(() => import('./import/import-products')),
  NewImportProducts: lazy(() => import('./import/new-import-products')),
  ImportProductsRanging: lazy(() => import('./import/import-products-ranging')),
  ImportProductStocks: lazy(() => import('./import/import-product-stocks')),
  ImportSuppliers: lazy(() => import('./import/import-suppliers')),
  Settings: lazy(() => import('./settings')),
  CreateLabel: lazy(() => import('./label/create')),
  EditLabel: lazy(() => import('./label/edit')),
  LabelsList: lazy(() => import('./label/list')),
  PurchaseOrderTypes: lazy(() => import('./purchase-order-types')),
  CreatePurchaseOrder: lazy(() => import('./create-purchase-order')),
  EditPurchaseOrder: lazy(() => import('./edit-purchase-order')),
  ViewPurchaseOrder: lazy(() => import('./purchase-order')),
  ReceivePurchaseOrder: lazy(() => import('./receive-purchase-order')),
  UnreceivePurchaseOrder: lazy(() => import('./unreceive-purchase-order')),
  BackOrderPurchaseOrder: lazy(() => import('./purchase-order-back-order')),
  // PurchaseOrdersList: lazy(() => import('./purchaseOrder/list')),
  PurchaseOrders: lazy(() => import('./purchase-orders')),
  SupplierBills: lazy(() => import('./supplier-bills')),
  // DraftPurchaseOrdersList: lazy(() => import('./purchaseOrder/draftList')),
  CancelPurchaseOrderFile: lazy(() =>
    import('./purchaseOrder/cancelPurchaseOrderFile')
  ),
  UnfulfillableOrders: lazy(() => import('./unfulfillable-orders')),
  CreateDemandForecast: lazy(() => import('./create-demand-forecast')),
  DemandForecastSchedules: lazy(() => import('./demand-forecast-schedules')),
  CreateDemandForecastSchedule: lazy(() =>
    import('./create-demand-forecast-schedule')
  ),
  EditDemandForecastSchedule: lazy(() =>
    import('./edit-demand-forecast-schedule')
  ),
  CreateScheduledForecast: lazy(() =>
    import('./create-demand-forecast/scheduled')
  ),
  CreateUnscheduledForecast: lazy(() =>
    import('./create-demand-forecast/unscheduled')
  ),
  CreateStockTransfer: lazy(() => import('./create-stock-transfer')),
  StockTransfers: lazy(() => import('./stock-transfers')),
  EditStockTransfer: lazy(() => import('./edit-stock-transfer')),
  ViewStockTransfer: lazy(() => import('./view-stock-transfer')),
  Users: lazy(() => import('./users')),
  CreateUser: lazy(() => import('./user/create')),
  EditUser: lazy(() => import('./user/edit')),
  RolesList: lazy(() => import('./user/role/list')),
  Brands: lazy(() => import('./brands')),
  Warehouses: lazy(() => import('./warehouses')),
  CreateWarehouse: lazy(() => import('./create-warehouse')),
  EditWarehouse: lazy(() => import('./edit-warehouse')),
  Integrations: lazy(() => import('./integrations')),
  Customers: lazy(() => import('./customers')),
  CreateCustomer: lazy(() => import('./create-customer')),
  EditCustomer: lazy(() => import('./edit-customer')),
  Shipping: lazy(() => import('./shipping')),
  CreateShippingMethod: lazy(() => import('./create-shipping-method')),
  EditShippingMethod: lazy(() => import('./edit-shipping-method')),
  CreateShippingCarrier: lazy(() => import('./create-shipping-carrier')),
  EditShippingCarrier: lazy(() => import('./edit-shipping-carrier')),
  Tags: lazy(() => import('./tags')),
  NewWarehouses: lazy(() => import('./new-warehouses')),
  SerialItems: lazy(() => import('./serial-items')),
  ViewRequestedPurchaseOrder: lazy(() =>
    import('./view-requested-purchase-order')
  ),
  Sources: lazy(() => import('./sources')),
  CreateSource: lazy(() => import('./create-source')),
  EditSource: lazy(() => import('./edit-source')),
  ContactTypes: lazy(() => import('./contact-types')),
  CreateContactType: lazy(() => import('./create-contact-type')),
  EditContactType: lazy(() => import('./edit-contact-type')),
  MergePurchaseOrders: lazy(() => import('./merge-purchase-orders')),
  Coupons: lazy(() => import('./coupons')),
  CreateCoupons: lazy(() => import('./create-coupon')),
  EditCoupon: lazy(() => import('./edit-coupon')),
  PrintProductLabels: lazy(() => import('./print-product-labels')),
  CreateRole: lazy(() => import('./create-role')),
  EditRole: lazy(() => import('./edit-role')),
  Statuses: lazy(() => import('./statuses')),
  StockMovementsTracker: lazy(() => import('./stock-movements-tracker')),
  ExportSuppliers: lazy(() => import('./export-suppliers')),
  DemandAlgorithmBuilder: lazy(() => import('./demand-forecast-algorithm')),
  CreateDemandAlgorithmBuilder: lazy(() =>
    import('./create-demand-algorithm-builder')
  ),
  EditDemandAlgorithm: lazy(() => import('./edit-demand-algorithm')),
  Attributes: lazy(() => import('./attributes')),
  CreateAttributeSet: lazy(() => import('./create-attribute-set')),
  EditAttributeSet: lazy(() => import('./edit-attribute-set')),
  CreateAttributeGroup: lazy(() => import('./create-attribute-group')),
  EditAttributeGroup: lazy(() => import('./edit-attribute-group')),
  CreateAttribute: lazy(() => import('./create-attribute')),
  EditAttribute: lazy(() => import('./edit-attribute')),
  DiscountRules: lazy(() => import('./discount-rules')),
  CreateDiscountRule: lazy(() => import('./create-discount-rule')),
  EditDiscountRule: lazy(() => import('./edit-discount-rule')),
  FreeFormCosts: lazy(() => import('./free-form-costs')),
  StockAdjustmentReasons: lazy(() => import('./stock-adjustment-reasons')),
  CreateStockAdjustmentReasons: lazy(() =>
    import('./create-stock-adjustment-reasons')
  ),
  JobTemplates: lazy(() => import('./job-templates')),
  CreateJobTemplate: lazy(() => import('./create-job-template')),
  EditJobTemplate: lazy(() => import('./edit-job-template'))
};
