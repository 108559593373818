import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import { colors } from 'config/antd.json';
import { useCurrentUser } from 'hooks/user';
import useInstallApp from 'hooks/useInstallApp';
import env from 'config/env';

const STAGE_LEVEL = env('STAGE_LEVEL');
const POS_DOMAIN = env('POS_DOMAIN');
const isProduction = STAGE_LEVEL === 'production';

export default function SideNav({ collapsed, onNavigate }) {
  const location = useLocation();

  const user = useCurrentUser();

  const [showAppInstall, installApp] = useInstallApp();

  const menu = useMemo(() => {
    return [
      {
        icon: 'home',
        name: 'Dashboard',
        path: '/',
        key: 'dashboard',
        exact: true
      },
      {
        icon: 'inbox',
        name: 'Products',
        path: '/products',
        key: 'products-root',
        children: [
          {
            name: 'Products',
            path: '/products/',
            key: 'products'
          },
          {
            name: 'Serial Items',
            path: '/serial-items/',
            key: 'serial-items'
          },
          {
            name: 'Product Labels',
            path: '/labels/',
            key: 'labels'
          },
          {
            name: 'Categories',
            path: '/categories/',
            key: 'categories'
          },
          {
            name: 'Free Form Costs Report',
            path: '/free-form-costs/',
            key: 'free-form-costs'
          }
        ]
      },
      {
        icon: 'barcode',
        name: 'Inventory',
        path: '/inventory',
        children: [
          {
            name: 'Product Inventory',
            path: '/inventory',
            key: 'inventory'
          },
          {
            name: 'Stock Movements Tracker',
            path: '/stock-movements/tracker',
            key: 'stock-movements-tracker'
          }
        ],
        key: 'inventory-root'
      },
      {
        icon: 'shopping-cart',
        name: 'Purchase Orders',
        shortName: 'Purchases',
        path: '/purchase-orders',
        key: 'purchase-orders-root',
        children: [
          {
            name: 'Purchase Orders',
            path: '/purchase-orders',
            key: 'purchase-orders'
          },
          {
            name: 'Orders to Fulfil',
            path: '/back-orders',
            key: 'back-orders'
          },
          {
            name: 'Create P.O.',
            path: '/purchase-orders/create',
            key: 'purchase-orders-create'
          },
          {
            name: 'Supplier Bills',
            path: '/purchase-orders/bills',
            key: 'supplier-bills'
          }
        ]
      },
      {
        icon: 'swap',
        name: 'Stock Transfers',
        shortName: 'Transfers',
        path: '/stock-transfers',
        key: 'stock-transfers-root',
        children: [
          {
            name: 'Stock Transfer Requests',
            path: '/stock-transfers',
            key: 'stock-transfers'
          },
          {
            name: 'Request Stock Transfer',
            path: '/request-stock-transfers',
            key: 'stock-transfers-create'
          }
        ]
      },
      {
        icon: 'fund',
        name: 'Demand',
        path: '/demand',
        children: [
          {
            name: 'Generate Demand Forecast',
            path: '/demand/create-demand-forecast',
            key: 'create-demand-forecast'
          },
          {
            name: 'Demand Forecast Schedules',
            path: '/demand/forecast-schedules',
            key: 'forecast-schedules'
          },
          {
            name: 'Algorithm Builder',
            path: '/demand/forecast-algorithms',
            key: 'demand-forecast-algorithms'
          }
        ],
        key: 'demand-root'
      },
      {
        icon: 'appstore',
        name: 'Suppliers',
        path: '/suppliers',
        key: 'suppliers-root',
        children: [
          {
            icon: 'appstore',
            name: 'Suppliers',
            path: '/suppliers',
            key: 'suppliers'
          },
          {
            icon: 'calendar',
            name: 'Re-Stock Schedule',
            path: '/restock-schedule',
            key: 'restock-schedule'
          },
          {
            icon: 'appstore',
            name: 'Brands',
            path: '/brands',
            key: 'brands'
          }
        ]
      },
      {
        icon: 'stock',
        name: 'Marketing',
        path: '/marketing',
        key: 'marketing',
        children: [
          {
            icon: 'percentage',
            name: 'Coupons',
            path: '/coupons',
            key: 'coupons'
          },
          {
            icon: 'percentage',
            name: 'Discount Rules',
            path: '/discount-rules',
            key: 'discount-rules',
            hidden: isProduction
          }
        ]
      },
      {
        icon: 'bar-chart',
        name: 'Reports',
        path: '/reports',
        key: 'reports'
      },
      {
        icon: 'export',
        name: 'Export Tools',
        title: 'Export Tools',
        shortName: 'Export',
        path: '/exports',
        key: 'exports'
      },
      {
        icon: 'import',
        name: 'Import Tools',
        title: 'Import Tools',
        shortName: 'Import',
        path: '/import',
        key: 'import'
      },
      // {
      //   icon: 'tags',
      //   name: 'Labels',
      //   path: '/labels',
      //   key: 'labels'
      // },
      user.is_admin
        ? {
            icon: 'setting',
            name: 'Settings',
            path: '/settings',
            key: 'settings',
            children: [
              // {
              //   icon: 'file-text',
              //   name: 'Job Templates',
              //   path: '/templates/jobs',
              //   key: 'job-templates'
              // },
              {
                icon: 'tags',
                name: 'Attribute Management',
                path: '/attribute',
                key: 'attribute'
              },
              {
                icon: 'tags',
                name: 'Tags',
                path: '/tags/job_orders',
                key: 'tags'
              },
              {
                icon: 'tags',
                name: 'Statuses',
                path: '/statuses/orders',
                key: 'statuses'
              },
              {
                icon: 'file',
                name: 'Forms',
                link: 'forms',
                // path: '/forms',
                key: 'forms'
              },
              {
                icon: 'shop',
                name: 'Stores',
                path: '/stores',
                key: 'stores'
              },
              {
                icon: 'shop',
                name: 'Manage Warehouses',
                path: '/manage/warehouses',
                key: 'manage/warehouses'
              },
              {
                icon: 'shop',
                name: 'Warehouses',
                path: '/warehouses',
                key: 'warehouses'
              },
              {
                icon: 'appstore',
                name: 'Integrations',
                path: '/integrations',
                key: 'integrations'
              },
              {
                icon: 'team',
                name: 'Users',
                path: '/users',
                key: 'users'
              },
              {
                icon: 'percentage',
                name: 'Shipping',
                path: '/shipping/methods',
                key: 'shipping'
              },
              {
                icon: 'branches',
                name: 'Sources',
                path: '/settings/sources',
                key: 'settings/sources'
              },
              {
                icon: 'contacts',
                name: 'Contact Types',
                path: '/settings/contact-types',
                key: 'settings/contact-types'
              },
              {
                icon: 'setting',
                name: 'ShoppyC Settings',
                path: '/settings/general',
                key: 'settings/general'
              }
            ]
          }
        : undefined,
      showAppInstall
        ? {
            customMarkup: (
              <Menu.Item
                key="install-app"
                style={{ backgroundColor: '#6c5ce7' }}
              >
                <a onClick={installApp}>
                  <Icon type="mobile" />
                  <span>Install</span>
                </a>
              </Menu.Item>
            )
          }
        : undefined
    ].filter(item => item);
  }, [installApp, showAppInstall, user]);

  const selectedKeys = menu.reduce((result, rootItem) => {
    const nextResult = [...result];

    if (
      matchPath(location.pathname, {
        path: rootItem.path,
        exact: rootItem.exact
      })
    ) {
      nextResult.push(rootItem.key);
    }

    if (rootItem.children) {
      rootItem.children.forEach(childItem => {
        if (
          matchPath(location.pathname, {
            path: childItem.path,
            exact: childItem.exact
          })
        ) {
          if (nextResult.indexOf(rootItem.key) === -1) {
            nextResult.push(rootItem.key);
          }

          nextResult.push(childItem.key);
        }
      });
    }

    return nextResult;
  }, []);

  const handleSelect = () => {
    onNavigate();
  };

  return (
    <Wrapper>
      <Menu
        selectedKeys={selectedKeys}
        mode="inline"
        onSelect={handleSelect}
        theme="dark"
      >
        {renderMenu(menu, collapsed, true)}
      </Menu>
    </Wrapper>
  );

  // return (
  //   <Wrapper>
  //     <Menu mode="inline" onSelect={handleSelect} theme="dark">
  //       <Menu.Item>
  //         <Link to="/">
  //           <Icon type="home" />
  //           <span>Dashboard</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item>
  //         <Link to="/products">
  //           <Icon type="gift" />
  //           <span>Products</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item>
  //         <Link to="/inventory">
  //           <Icon type="barcode" />
  //           <span>Inventory</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.SubMenu
  //         title={
  //           <>
  //             <Icon type="shopping-cart" />
  //             {collapsed && <small>P. Orders</small>}
  //             <span>Purchase Orders</span>
  //           </>
  //         }
  //       >
  //         <Menu.Item>
  //           <Link to="/purchase-orders">Purchase Orders</Link>
  //         </Menu.Item>
  //         <Menu.Item>
  //           <Link to="/purchase-order/upload">Create P.O.</Link>
  //         </Menu.Item>
  //       </Menu.SubMenu>
  //
  //       <Menu.SubMenu
  //         title={
  //           <>
  //             <Icon type="appstore" />
  //             <span>Suppliers</span>
  //           </>
  //         }
  //       >
  //         <Menu.Item>
  //           <Link to="/suppliers">
  //             <Icon type="appstore" />
  //             <span>Suppliers</span>
  //           </Link>
  //         </Menu.Item>
  //         <Menu.Item>
  //           <Link to="/replenishment/suppliers-calendar">
  //             {' '}
  //             <Icon type="calendar" />
  //             Suppliers Calendar
  //           </Link>
  //         </Menu.Item>
  //         <Menu.Item>
  //           <Link to="/brands">
  //             <Icon type="appstore" />
  //             <span>Brands</span>
  //           </Link>
  //         </Menu.Item>
  //       </Menu.SubMenu>
  //
  //       <Menu.Item>
  //         <Link to="/reports">
  //           <Icon type="bar-chart" />
  //           <span>Reports</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item title="Export Tools">
  //         <Link to="/exports">
  //           <Icon type="export" />
  //           {collapsed && <small>Export</small>}
  //           <span>Export Tools</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item title="Import Tools">
  //         <Link to="/import">
  //           <Icon type="import" />
  //           {collapsed && <small>Import</small>}
  //           <span>Import Tools</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item>
  //         <Link to="/labels">
  //           <Icon type="tags" />
  //           <span>Labels</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       <Menu.Item>
  //         <Link to="/marketing">
  //           <Icon type="stock" />
  //           <span>Marketing</span>
  //         </Link>
  //       </Menu.Item>
  //
  //       {user.is_admin && (
  //         <Menu.SubMenu
  //           title={
  //             <>
  //               <Icon type="setting" />
  //               <span>Settings</span>
  //             </>
  //           }
  //         >
  //           <Menu.Item>
  //             <Link to="/categories">
  //               <Icon type="build" />
  //               <span>Categories</span>
  //             </Link>
  //           </Menu.Item>
  //           <Menu.Item>
  //             <Link to="/stores">
  //               <Icon type="shop" />
  //               <span>Stores</span>
  //             </Link>
  //           </Menu.Item>
  //           <Menu.Item>
  //             <Link to="/warehouses">
  //               <Icon type="shop" />
  //               <span>Warehouses</span>
  //             </Link>
  //           </Menu.Item>
  //           <Menu.Item>
  //             <Link to="/integrations">
  //               <Icon type="appstore" />
  //               <span>Integrations</span>
  //             </Link>
  //           </Menu.Item>
  //           <Menu.Item>
  //             <Link to="/users">
  //               <Icon type="team" />
  //               <span>Users</span>
  //             </Link>
  //           </Menu.Item>
  //           <Menu.Item>
  //             <Link to="/settings/general">
  //               <Icon type="setting" />
  //               <span>ShoppyCommerce Settings</span>
  //             </Link>
  //           </Menu.Item>
  //         </Menu.SubMenu>
  //       )}
  //
  //       {/*<Menu.Item
  //       style={{
  //         backgroundColor: '#dc54b963',
  //         border: '1px solid #dc54b9',
  //         borderWidth: '1px 0'
  //       }}
  //     >
  //       <a href={getPosUrl()} target="_blank">
  //         <Icon
  //           components={() => (
  //             <img style={{ display: 'block' }} src={logoSrc} height={14} />
  //           )}
  //         />
  //         <span style={{ color: '#dc54b9' }}>ShoppyCommerce POS (Beta)</span>
  //       </a>
  //     </Menu.Item>*/}
  //
  //       {showAppInstall && (
  //         <Menu.Item style={{ backgroundColor: '#6c5ce7' }}>
  //           <a onClick={installApp}>
  //             <Icon type="mobile" />
  //             <span>Install</span>
  //           </a>
  //         </Menu.Item>
  //       )}
  //     </Menu>
  //   </Wrapper>
  // );
}

function renderMenu(menu, collapsed, root = false) {
  if (menu.hidden) return null;
  if (root) {
    return menu.map(child => renderMenu(child, collapsed));
  }

  if (menu.customMarkup) {
    return menu.customMarkup;
  }

  const menuSelf = (
    <>
      {menu.icon ? <Icon type={menu.icon} /> : null}
      {collapsed && menu.shortName ? <small>{menu.shortName}</small> : null}
      <span>{menu.name}</span>
    </>
  );

  if (menu.children) {
    return (
      <Menu.SubMenu key={menu.key} title={menuSelf}>
        {menu.children.map(child => renderMenu(child, collapsed))}
      </Menu.SubMenu>
    );
  }

  if (menu.link) {
    return (
      <Menu.Item key={menu.key} title={menu.title}>
        <a href={`${POS_DOMAIN}/${menu.link}`} target="_blank">
          {menuSelf}
        </a>
      </Menu.Item>
    );
  }

  return (
    <Menu.Item key={menu.key} title={menu.title}>
      <Link to={menu.path}>{menuSelf}</Link>
    </Menu.Item>
  );
}

let Wrapper = styled('div')`
  ul.ant-menu-root {
    width: 100% !important;

    > li {
      border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;

      .anticon {
        transition: none !important;
      }

      li .anticon {
        display: none;
      }

      &.ant-menu-item {
        height: 60px;

        > a {
          line-height: 60px;
        }
      }

      &.ant-menu-submenu {
        &.ant-menu-submenu-selected {
          background-color: ${colors['primary-color']} !important;
          color: #fff !important;
        }

        > .ant-menu-submenu-title {
          height: 60px;
          line-height: 60px;
          margin: 0;
        }
      }
    }

    li.ant-menu-item > a,
    li.ant-menu-submenu > .ant-menu-submenu-title {
      .anticon + small {
        display: none;
      }
    }

    &.ant-menu-inline-collapsed {
      li.ant-menu-item > a,
      li.ant-menu-submenu > .ant-menu-submenu-title {
        .anticon {
          display: block;
          font-size: 18px;
          height: 20px;
          line-height: 20px;
          margin-top: 12px;
        }

        .anticon + span,
        .anticon + small {
          bottom: 10px;
          display: block;
          font-size: 14px;
          left: 0;
          line-height: 12px;
          max-width: 100%;
          opacity: 0.6;
          position: absolute;
          right: 0;
          text-align: center;
        }

        .anticon + small + span {
          display: none;
        }
      }
    }
  }
`;
